import React from 'react'
import classNames from 'classnames'
import {useDispatch, useSelector} from 'react-redux'
import Link from 'next/link'
import AsyncAction from '../../shared/AsyncAction'
import {Cart16Svg} from '../../../svg'
import {cartAddItem, getUpOrCrossProd} from '../../../store/cart'
import {url} from '../../../services/utils'
import Currency from '../../shared/Currency'
import defaultPicture from '../../../images/defaultpic.webp'
import {FormattedMessage} from 'react-intl'
import {setCrossValid, setPopupName, setTempData,} from "../../../store/general";
import {IState} from "../../../types/state";
import {domainUrl} from '../../../helper'
import InStockProducts from "../../shared/InStockProducts";
import cls from "./search.module.scss"
import Image from "next/image";

//@ts-ignore
function Suggestions(props) {
    const {context, className, products} = props
    const rootClasses = classNames(
        `${cls.suggestions} ${cls[`suggestions--location--${context}`]}`,
        className,
    )

    const dispatch = useDispatch();
    const cartToken = useSelector((state: IState) => state.cartToken);
    const customer = useSelector((state: IState) => state.customer)
    // const apiToken = useSelector((state: IState) => state.general.apiToken)
    const backorders = useSelector((state: IState) => state.general.Backorders);
    const outOfStock = Boolean(+useSelector((state: IState) => state.general.coreConfigs.catalog_products_homepage_out_of_stock_items?.value))
    const rate = useSelector((state: IState) => state.rate.exchange_rate.rate);
    const selectedData = useSelector((state: IState) => state.locale.code)
    const currency = useSelector((state: IState) => state.rate.current.code)

    //@ts-ignore
    const openUpCrossProd = (product) => {
        let hasUpSell = product?.product_flat[0].has_up_sell == 1;
        let hasCrossSell = product?.product_flat[0].has_cross_sell == 1;
        let page = true;

        if (!hasUpSell && hasCrossSell) {
            dispatch(getUpOrCrossProd(product.id, 'cross-sell', selectedData, currency, rate))
            dispatch(setPopupName("crossSell"));
        }
        if (hasUpSell) {
            dispatch(setPopupName("upsell"));
            dispatch(getUpOrCrossProd(product.id, 'up-sell', selectedData, currency, rate))
        }
    };

    const isProductInStock = (product: { product_inventories: { qty: any; }[]; }) => {
        let qty = product?.product_inventories?.[0]?.qty
        return Number(qty) > 0 || (Number(qty) === 0 && backorders)
    }


    // useEffect(() => {
    //   ///setToken(apiToken);
    //   fetch(apiUrlWithStore('/api/checkout/cart/token'))
    //     .then((responce) => responce.json())
    //     .then((res) => {
    //       setToken(res)
    //     })
    //     .catch((err) => console.error(err))
    // }, [apiToken])



    const list =
        products &&
        //@ts-ignore
        products.map((product) => {
            console.log(product, "product")
                return product.id != -1 ?
                    <InStockProducts
                        qty={product?.product_inventories[0]?.qty}
                        stockID="no_matching_items"
                        stockValue="No matching items"
                        key={product?.product_flat[0]?.product_id}
                    >
                        <li className={cls.suggestions__item}>
                            {product?.product_images &&
                            product?.product_images?.length > 0 && (
                                <div className={`${cls["suggestions__item-image"]} ${cls["product-image"]}`}>
                                    <div className={`${cls["search-product-image__body-fms"]}`}>
                                        <Image
                                            src={domainUrl(`${props.dbName}/storage/${product?.product_images[0]?.path}`)}
                                            alt={product?.product_flat[0]?.name}
                                            placeholder="blur"
                                            width={50}
                                            height={50}
                                            priority
                                            blurDataURL={`/zega/blur1.webp`}
                                        />
                                    </div>
                                </div>
                            ) ? (
                                <div className={`${cls["suggestions__item-image"]} ${cls["product-image"]}`}>
                                    <div className={`${cls["search-product-image__body-fms"]}`}>
                                        <Link href={url.product(product)}>
                                            <a>
                                                <Image
                                                    src={domainUrl(`${props.dbName}/storage/${product?.product_images[0]?.path}`)}
                                                    alt={product?.product_flat[0]?.name}
                                                    placeholder="blur"
                                                    width={50}
                                                    height={50}
                                                    priority
                                                    blurDataURL={`/zega/blur1.webp`}
                                                />
                                            </a>

                                        </Link>
                                    </div>
                                </div>
                            ) : (
                                <div className={`${cls["suggestions__item-image"]} ${cls["product-image"]}`}>
                                    <div className={`${cls["search-product-image__body-fms"]}`}>
                                        <Image
                                            src={domainUrl(`${props.dbName}${defaultPicture.src}`)}
                                            alt={product?.product_flat[0]?.name}
                                            placeholder="blur"
                                            width={50}
                                            height={50}
                                            priority
                                            blurDataURL={`/zega/blur1.webp`}
                                        />
                                    </div>
                                </div>
                            )}
                            <div className={cls["suggestions__item-info"]}>
                                <>
                                    <Link
                                        className={cls["suggestions__item-name"]}
                                        href={url.product(product)}
                                    >
                                        <a> {product?.product_flat[0]?.name}</a>

                                    </Link>
                                    <div
                                        //@ts-ignore
                                        className={cls["suggestions__item-meta"]}>{product?.sku_option[0]?.translations?.find(el => el.locale === selectedData)?.name}: {product?.product_flat[0]?.sku}</div>
                                </>
                            </div>
                            <div className={cls["suggestions__item-price"]}>
                                <Currency
                                    value={
                                        product.special_price
                                            ? product.special_price
                                            : product.price ||
                                            Number(product?.min_price
                                            && Number(product?.min_price > 0)
                                            && product.products.length === 0
                                                ? product?.min_price
                                                : product?.product_flat?.[0]?.min_price
                                            ).toFixed(2)
                                    }
                                />
                                {' '}
                            </div>
                            {context === 'header' && (
                                <div className={classNames(`${cls["suggestions__item-actions"]}`,
                                    product?.product_inventories[0]?.qty === 0
                                    //@ts-ignore
                                    && backorders == 0
                                    //@ts-ignore
                                    && !outOfStock && cls["button_disabled"]
                                )}>

                                    {(product && product?.type === "configurable") ||
                                    (product?.type === "bundle") || !isProductInStock(product) ? (
                                            <Link href={url.product(product)}>
                                                <a>
                                                    <button
                                                        type="button"
                                                        className={classNames(
                                                            `btn btn-primary btn-sm btn-svg-icon ${cls["suggestion-btn"]}`,
                                                            {
                                                                // 'btn-loading': loading,
                                                            },
                                                        )}
                                                        //   disabled={!backorders && !isProductInStock(product)}
                                                    >
                                                        <Cart16Svg/>
                                                    </button>
                                                </a>

                                            </Link>
                                        ) :
                                        product.product_flat[0].has_up_sell == 0 && product.product_flat[0].has_cross_sell == 0 ? (
                                            <AsyncAction
                                                //@ts-ignore
                                                action={() =>
                                                    dispatch(cartAddItem(
                                                        { id: product?.product?.id },
                                                        1,
                                                        cartToken,
                                                        customer,
                                                        selectedData,
                                                    ))
                                                }
                                                //@ts-ignore
                                                render={({run, loading}) => (
                                                    <button
                                                        type="button"
                                                        onClick={(e) => {
                                                            e.preventDefault();
                                                            dispatch(setCrossValid(false));
                                                            run();
                                                        }}
                                                        className={classNames(
                                                            `btn btn-primary btn-sm btn-svg-icon ${cls["suggestion-btn"]}`,
                                                            {
                                                                'btn-loading': loading,
                                                            },
                                                        )}
                                                        //  disabled={!backorders && !isProductInStock(product)}
                                                    >
                                                        <Cart16Svg/>
                                                    </button>
                                                )}
                                            />
                                        ) : product.product_flat[0].has_up_sell == 0 && product.product_flat[0].has_cross_sell == 1 ? (
                                            <AsyncAction
                                                //@ts-ignore
                                                action={() =>
                                                    dispatch(cartAddItem(
                                                        { id: product?.product?.id },
                                                        1,
                                                        cartToken,
                                                        customer,
                                                        selectedData,
                                                    ))
                                                }
                                                //@ts-ignore
                                                render={({run, loading}) => (
                                                    <button
                                                        type="button"
                                                        onClick={(e) => {
                                                            e.preventDefault();
                                                            run();
                                                            dispatch(setTempData([product]));
                                                            dispatch(setCrossValid(true));
                                                            openUpCrossProd(product);
                                                        }}
                                                        className={classNames(
                                                            `btn btn-primary btn-sm btn-svg-icon ${cls["suggestion-btn"]}`,
                                                            {
                                                                'btn-loading': loading,
                                                            },
                                                        )}
                                                        // disabled={!backorders && !isProductInStock(product)}
                                                    >
                                                        <Cart16Svg/>
                                                    </button>
                                                )}
                                            />
                                        ) : (
                                            <AsyncAction
                                                //@ts-ignore
                                                render={({run, loading}) => (
                                                    <button
                                                        type="button"
                                                        onClick={(e) => {
                                                            e.preventDefault();
                                                            run();
                                                            dispatch(setTempData([product]));
                                                            dispatch(setCrossValid(false));
                                                            openUpCrossProd(product);
                                                        }}
                                                        className={classNames(
                                                            `btn btn-primary btn-sm btn-svg-icon ${cls["suggestion-btn"]}`,
                                                            {
                                                                'btn-loading': loading,
                                                            },
                                                        )}
                                                        // disabled={!backorders && !isProductInStock(product)}
                                                    >
                                                        <Cart16Svg/>
                                                    </button>
                                                )}
                                            />
                                        )}
                                </div>
                            )}
                        </li>
                    </InStockProducts>
                    : <div className={cls["search__fm_dr_Message"]}>
                        <FormattedMessage
                            id="no_matching_items"
                            defaultMessage="No matching items"
                        />
                    </div>
            }
        )
    return (
        <div className={rootClasses}>
            <ul className={cls["suggestions__list"]}>{list}</ul>
        </div>
    )
}

export default Suggestions