import React, {memo, useEffect} from "react";
import queryString from "query-string";
import {FormattedMessage} from "react-intl";
import {SearchssSvg} from "../../../svg";
import {useRouter} from "next/router";
import {IProps, queryType} from "./types";
import cls from "./search.module.scss"

const SearchComponent: React.FC<IProps> = (
    {
        setQuery,
        cancelFn,
        wrapper,
        handleFocus,
        handleKeyDown,
        suggestedProducts,
        handleSearchButt,
        closeButton,
        query,
        handleChangeQuery,
        close,
    }
) => {
    const history = useRouter();
    const location = history.asPath;

    useEffect(() => {
        const {search} = queryString.parse(window.location.search);
        if (search) {
            setQuery(search);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location]);


    useEffect(() => close(), [close, location]);

    useEffect(() => {
        const onGlobalClick = (event: { target: JSX.Element }) => {
            if (wrapper.current && !wrapper.current.contains(event.target as unknown as Node)) {
                close();
            }
        };
        document.addEventListener("mousedown", onGlobalClick as unknown as (this: Document, ev: MouseEvent) => void);
        return () => document.removeEventListener("mousedown", onGlobalClick as unknown as (this: Document, ev: MouseEvent) => void);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [close]);

    // Cancel previous typing.
    useEffect(() => () => cancelFn(), [cancelFn]);
    return (
        <div className={cls.search__form}>
            <FormattedMessage
                id="search_looking_for"
                defaultMessage="I am looking for․․."
            >
                {(placeholder: any) => (
                    <input
                        onChange={handleChangeQuery}
                        onFocus={handleFocus}
                        onKeyDown={handleKeyDown}
                        value={
                            (queryString.parse((location as unknown as {
                                search: string
                            }).search) as unknown as queryType).search
                            || query as unknown as string
                        }
                        className={cls.search__input}
                        name="search"
                        placeholder={placeholder}
                        aria-label="Site search"
                        type="text"
                        autoComplete="off"
                    />
                )}
            </FormattedMessage>

            <button
                className={`${cls.search__button} ${cls["search__button--type--submit"]}`}
                aria-label="Search"
                onClick={() => {
                    if (suggestedProducts[0]) {
                        if (suggestedProducts[0].id != -1) {
                            handleSearchButt();
                            setQuery("");
                        }
                    }
                }}
            >
                <SearchssSvg/>
            </button>
            {closeButton}
            <div className={cls.search__border}/>
        </div>
    )
}

export default memo(SearchComponent);