import React, {useCallback, useEffect, useMemo, useRef, useState} from "react";
import classNames from "classnames";
import {useRouter} from "next/router";

import {useSelector} from "react-redux";
import {Cross20Svg} from "../../../svg";
import Suggestions from "./Suggestions";
import SearchComponent from "../Search/index";
import shopApi from "../../../api/shop";
import {IState} from "../../../types/state";
import cls from "./search.module.scss"

interface IProps {
    className?: string,
    context?: string,
    onClose?: () => void,
    dbName?: string,
}

function Search(props: IProps) {
    const {context, className, onClose} = props;

    const history = useRouter();


    const [query, setQuery] = useState<string | Array<string | null>>("");
    const [cancelFn, setCancelFn] = useState<() => void>(() => () => {
    });
    const [suggestionsOpen, setSuggestionsOpen] = useState<boolean>(false);
    const [hasSuggestions, setHasSuggestions] = useState<boolean>(false);
    const [suggestedProducts, setSuggestedProducts] = useState<Array<{ id: number }>>([]);


    const selectedData = useSelector((state: IState) => state.locale.code);
    const dbName = useSelector((state: IState) => state.general.dbName);
    const searchOpen = useSelector((state: IState) => state.mobileMenu.searchOpen);

    // useEffect(() => {
    //     if(searchOpen){
    //         document.body.style.overflowY = "hidden"
    //     }else document.body.style.overflowY = "scroll"
    // }, [searchOpen])

    const wrapper = useRef<HTMLInputElement>(null);

    const close = useCallback(() => {
        if (onClose) {
            onClose();
        }
        setSuggestionsOpen(false);
    }, []);

    const handleFocus = () => {
        setSuggestionsOpen(true);
    };

    const handleChangeQuery = useCallback((event: { target: { value: string | Array<string | null> } }) => {
        let canceled = false;
        let timer: NodeJS.Timeout;

        const newCancelFn = () => {
            canceled = true;
            clearTimeout(timer);
        };

        const query = event.target.value;

        setQuery(query);
        if (query.length >= 3) {
            timer = setTimeout(() => {
                const options = {
                    limit: 10,
                    lang: selectedData,
                    dbName: props.dbName,
                    category: "",
                };

                shopApi.getSeachProducts({query, options}).then((products: any) => {

                    if (canceled) {
                        return;
                    }

                    let newProduct = products?.data?.filter((item: string | any[]) => {
                        if (item.length === 0) {
                            return
                        }
                        return item
                    });


                    if (newProduct?.length > 0) {
                        setSuggestedProducts(newProduct);
                        setHasSuggestions(newProduct.length > 0);
                        setSuggestionsOpen(true);
                    } else {
                        setSuggestedProducts([{id: -1}]);
                        setHasSuggestions(newProduct?.length == 0);
                        setSuggestionsOpen(true);
                    }
                });
            }, 1000);
        }
        if (query === "") {
            setHasSuggestions(false);
        }

        setCancelFn(() => newCancelFn);
    }, [props.dbName, selectedData]);

    const handleBlur = useCallback(() => {
        setTimeout(() => {
            if (!document.activeElement || document.activeElement === document.body) {
                return;
            }

            // Close suggestions if the focus received an external element.
            if (
                wrapper.current &&
                !wrapper.current.contains(document.activeElement)
            ) {
                close();
            }
        }, 10);
    }, [close]);

    // Close suggestions when the Escape key has been pressed.
    const handleKeyDown = useCallback((event: { which: number, preventDefault: () => void }) => {
        // Escape.
        if (event.which === 27) {
            close();
        }

        if (event.which == 13 && query.length > 0) {
            event.preventDefault();
            localStorage.setItem(
                "searchProductsFm",
                JSON.stringify(suggestedProducts)
            );
            history.push({
                pathname: `/catalog/search/${query}`,
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [history, query, suggestedProducts]);

    const handleSearchButt = useCallback(() => {
        if (query.length > 0) {
            localStorage.setItem(
                "searchProductsFm",
                JSON.stringify(suggestedProducts)
            );
            history.push({
                pathname: "/catalog/search/" + query,
            });
        }
    }, []);

    const rootClasses = classNames(
        `${cls.search} ${cls[`search--location--${context}`]}`,
        className,
        suggestionsOpen && cls["search--suggestions-open"],
        hasSuggestions && cls["search--has-suggestions"],
    );

    const closeButton = useMemo(() => {
        if (context === "mobile-header") {
            return (
                <button
                    className={`${cls.search__button} ${cls["search__button--type--close"]}`}
                    type="button"
                    onClick={close}
                >
                    <Cross20Svg/>
                </button>
            )
        }
        return ""
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [context])


    return (
        <div className={rootClasses} ref={wrapper} onBlur={handleBlur}>
            <div className={cls.search__body}>
                <SearchComponent
                    cancelFn={cancelFn}
                    setQuery={setQuery}
                    wrapper={wrapper}
                    handleFocus={handleFocus}
                    handleKeyDown={handleKeyDown}
                    suggestedProducts={suggestedProducts}
                    handleSearchButt={handleSearchButt}
                    closeButton={closeButton}
                    query={query}
                    handleChangeQuery={handleChangeQuery}
                    close={close}
                />
                <Suggestions
                    className={cls.search__suggestions}
                    context={context}
                    products={suggestedProducts}
                    setQuery={setQuery}
                    dbName={dbName}
                />
                {/*<div className={cls.background_layer}></div>*/}
            </div>
        </div>
    );
}

export default Search;