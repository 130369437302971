import React from 'react';
import {useSelector} from 'react-redux';
import {IState} from "../../types/state";
import cls from "./shared.module.scss"

function Currency({value}: any) {
    const locale = useSelector((state: IState) => state.locale.code)
    const symbol = useSelector((state: IState) => state.rate.current.symbol)

    // value = value + ""
    // const removeUpTo6Zeros = (val: string | any[]) => {
    //     let i;
    //     for (i = 0; i < 6; i++) {
    //         if (val[val.length - 1 - i] !== '0') break;
    //     }
    //     return i ? val.slice(0, -i) : val;
    // };
    //
    // let valueArray = value ? value?.split(".") : ""
    //
    // if (valueArray[1]) {
    //     let valueAfterDot = removeUpTo6Zeros(valueArray[1])
    //     if (valueAfterDot) {
    //         value = [valueArray[0], valueAfterDot].join(".")
    //     } else {
    //         value = valueArray[0]
    //     }
    // }
    value = isNaN(Number(Number(value).toFixed(2) as string)) ? value : Number(value).toFixed(2)

    return (
        <div className={cls["currency_value"]}>
            {locale === 'hy' ? (
                <>
                    <span>{`${value}`}</span>
                    <span>{!isNaN(+value) ? symbol : ""}</span>
                </>
            ) : (
                <>
                    {' '}
                    <span>{!isNaN(+value) ? symbol : ""}</span>
                    <span>{`${value}`}</span>

                </>
            )}
        </div>
    )
}

export default Currency
